<template>
  <fieldset>
    <div class="content">
      <h1>{{ $t('page.reqConfirmation.title') }}</h1>
      <p>{{ $t('page.reqConfirmation.description') }}</p>
    </div>
    <component
      v-for="(step, stepIndex) in reviewSteps"
      :key="stepIndex"
      :is="`page-${step.component}`"
      @edit="setStepAndViewModeCreate(stepIndex)"
      class="page-confirmation__review"
    >
    </component>
    <b-field
      :label="getFieldLabel('additionalInfo')"
      :type="getFieldType('additionalInfo')"
      :message="getFieldMessage('additionalInfo')"
    >
      <b-input
        v-model="model.additionalInfo"
        maxlength="2000"
        type="textarea"
        autocomplete="off"
      >
      </b-input>
    </b-field>
    <b-field
      :type="getFieldType('acknowledgeReqRequest')"
      :message="getFieldMessage('acknowledgeReqRequest')"
    >
      <b-checkbox v-model="acknowledgeReqRequest">
        {{ getFieldLabel('acknowledgeReqRequest') }}
      </b-checkbox>
    </b-field>
    <div class="has-text-centered">
      <b-button
        type="is-blue"
        icon-right="paper-plane"
        class="form-navigation__button"
        @click="submit"
        :loading="loading"
        :disabled="!acknowledgeReqRequest || submitDisabled"
      >
        {{ $t('page.reqConfirmation.submit') }}
      </b-button>
    </div>
  </fieldset>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import api from '@/api';
import page from '@/mixins/page';
import validator from '@/mixins/validator';
import { getReqFormData } from '@/utils/helper';

export default {
  components: {
    PageNeq: () => import('@/components/page/req/PageNeq'),
    PageOtherIndividual: () => import('@/components/page/req/PageOtherIndividual'),
    PageReqDirector: () => import('@/components/page/req/PageReqDirector'),
    PageValidateInfo: () => import('@/components/page/req/PageValidateInfo')
  },
  name: 'PageReqConfirmation',
  mixins: [
    page('reqConfirmation'),
    validator()
  ],
  computed: {
    ...mapState('form', [
      'currentForm',
      'currentStep',
      'steps',
      'directorFiles'
    ]),
    reviewSteps () {
      return this.steps.length ? this.steps.slice(0, this.steps.findIndex(step => step.component === 'reqConfirmation')) : [];
    }
  },
  data () {
    return {
      acknowledgeReqRequest: false,
      submitDisabled: false,
      loading: false
    };
  },
  created () {
    this.setViewModeReview();
    this.$nextTick(() => {
      window.scrollTo(0, 0);
      document.getElementsByClassName('form-view-content-navigation').forEach(el => el?.scrollTo(0, 0));
    });
  },
  methods: {
    ...mapActions('form', [
      'setStep'
    ]),
    ...mapActions('viewMode', [
      'setViewModeCreate',
      'setViewModeReview'
    ]),
    setStepAndViewModeCreate (stepIndex) {
      this.setViewModeCreate();
      this.setStep(stepIndex);
    },
    submit () {
      this.submitDisabled = true;
      this.loading = true;

      const draftId = this.$route.query.draftId;
      const apiCall = draftId ? api.submitDraft : api.createReqForm;

      apiCall(getReqFormData(this.$i18n.locale, this.storedModel, this.directorFiles), draftId)
        .then(() => {
          this.$buefy.dialog.alert({
            title: this.$t('page.reqConfirmation.success.title'),
            message: this.$t('page.reqConfirmation.success.message'),
            type: 'is-success',
            hasIcon: true,
            icon: 'check-circle',
            iconPack: 'far',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: this.$t('page.reqConfirmation.success.confirmText'),
            onConfirm: () => this.$router.push({ name: 'professionalaccess-req', query: { quit: 'force' } })
          });
        })
        .catch(() => {
          this.submitDisabled = false;
          this.$buefy.dialog.alert({
            title: this.$t('page.reqConfirmation.error.title'),
            message: this.$t('page.reqConfirmation.error.message'),
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'far',
            ariaRole: 'alertdialog',
            ariaModal: true
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validate () {
      if (!this.acknowledgeReqRequest)
        this.addError('acknowledgeReqRequest');
    }
  }
};
</script>
